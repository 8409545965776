import { render, staticRenderFns } from "./LetReviewTelegram.vue?vue&type=template&id=5f2348c2&scoped=true&"
import script from "./LetReviewTelegram.vue?vue&type=script&lang=js&"
export * from "./LetReviewTelegram.vue?vue&type=script&lang=js&"
import style0 from "./LetReviewTelegram.vue?vue&type=style&index=0&id=5f2348c2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f2348c2",
  null
  
)

export default component.exports