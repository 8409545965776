<template>
  <div class="mb-4">
    <MultiStepWindow
      :steps="steps"
      :currentStep="currentStep"
      contentClass="inner-container"
      class="form form--1"
      :class="{
        'form--blogger': reviewData.customer_kind === 'blogger',
        'form--commerce': reviewData.customer_kind === 'shop',
        'd-none': complete,
      }"
    >
      <template #upperContent>
        <div v-if="review" class="inner-container">
          <div class="refuse-reason">
            <p style="font-weight: 500">Причина отклонения:</p>
            <ul v-if="review.refuse_reasons.length" style="padding-left: 20px">
              <li
                v-for="(reason, idx) in review.refuse_reasons"
                :id="`${idx}-reason`"
                :key="`${reason}-awdjuh87h2hd`"
                :title="refuseReasons.filter((val) => val.id === reason)[0].text"
                style="cursor: pointer; list-style: disc; white-space: pre-line"
              >
                {{ refuseReasons.filter((val) => val.id === reason)[0].title }}
              </li>
            </ul>
            <span v-else> не указана</span>
          </div>
        </div>
      </template>
      <template #contentHeader>
        <div class="title-part">
          <h1 class="title-part__title">
            <template v-if="!review"> Оставить отзыв на рекламу в канале </template>
            <template v-else-if="review.left_resent_tries > 0">
              Редактирование отзыва №{{ review.id }}. Осталось {{ review.left_resent_tries }} из 2
              попыток
            </template>
            <template v-else> У вас не осталось попыток редактирования отзыва! </template>
          </h1>
          <span class="title-part__step subtitle gray-text"
            >Шаг {{ currentStep }} из {{ steps }}</span
          >
        </div>
      </template>
      <template #step1>
        <section style="background-color: #fffce3; border-radius: 10px" class="p-3 mb-4 lh-18d6">
          Не пишите отзывы сами на себя — наши модераторы это быстро определят и забанят нарушителя
        </section>
        <div class="form-part">
          <h3 class="form-part__title">Какой у вас аккаунт?</h3>
          <b-button-group class="w-100">
            <b-button
              class="w-50"
              :variant="reviewData.customer_kind === 'blogger' ? 'primary' : 'outline-default'"
              @click="reviewData.customer_kind = 'blogger'"
            >
              Блогер или паблик
            </b-button>
            <b-button
              class="w-50"
              :variant="reviewData.customer_kind === 'shop' ? 'primary' : 'outline-default'"
              @click="reviewData.customer_kind = 'shop'"
            >
              Коммерческий
            </b-button>
          </b-button-group>
        </div>
        <!--div
          class="form-part ad-mode"
          :class="{ 'ad-mode--chosen': review ? review.customer_kind : false }"
        >
          <h3 class="form-part__title">
            Вы блогер или у вас коммерческий аккаунт (например, магазин)?
          </h3>
          <div class="row">
            <button class="button" @click="reviewData.customer_kind = 'blogger'">Блогер</button>
            <button class="button" @click="reviewData.customer_kind = 'shop'">Коммерция</button>
          </div>
          <span
            class="warning"
            v-if="!$v.reviewData.customer_kind.required && $v.reviewData.customer_kind.$error"
          >
            Выберите вариант, чтобы продолжить
          </span>
        </!div-->
        <div class="form-part">
          <h3 class="form-part__title required-field-star">Ссылка на ваш аккаунт</h3>
          <instaname-input
            :class="{ error: $v.reviewData.customer.$error }"
            v-model="$v.reviewData.customer.$model"
          />
          <span
            class="warning"
            v-if="!$v.reviewData.customer.required && $v.reviewData.customer.$error"
          >
            Заполните это поле, чтобы продолжить
          </span>
          <span class="warning warning--equals">
            Мой аккаунт и аккаунт блогера, у которого была реклама, должны быть разными
          </span>
        </div>
        <div class="form-part" v-if="reviewData.customer_kind === 'blogger'">
          <h3 class="form-part__title required-field-star">Темы аккаунта заказчика</h3>
          <multiselect
            :class="{ error: $v.reviewData.customer_tags.$anyError }"
            :options="tags"
            placeholder="Выберите темы (максимум 3)"
            select-label=""
            deselectLabel=""
            selectedLabel="Выбрано"
            label="name"
            track-by="id"
            searchable="searchable"
            :multiple="true"
            :max="3"
            v-model="reviewData.customer_tags"
          >
            <span slot="noResult">Тема не найдена</span>
            <template slot="tagPlaceholder">Выберите тему</template>
          </multiselect>
          <span
            class="warning"
            v-if="!$v.reviewData.customer_tags.required && $v.reviewData.customer_tags.$error"
            >Выберите одну или несколько тем из списка, чтобы продолжить</span
          >
        </div>
        <div class="form-part">
          <h3 class="form-part__title required-field-star">Реклама была в канале</h3>
          <instaname-input
            :class="{ error: $v.reviewData.advertiser.$error }"
            v-model="$v.reviewData.advertiser.$model"
            @blur="check"
          />
          <span
            class="warning"
            v-if="!$v.reviewData.advertiser.required && $v.reviewData.advertiser.$error"
          >
            Заполните это поле, чтобы продолжить
          </span>
          <p v-if="reviewData.customer && reviewData.advertiser" class="fs-12 gray-text mt-1">
            Проверьте ники блогеров. При ошибочных ссылках отзыв не будет опубликован.
          </p>
        </div>
        <div class="form-part" id="calendar">
          <h3 class="form-part__title required-field-star">Дата выхода рекламы</h3>
          <date-picker
            :model-config="modelConfig"
            mode="date"
            timezonE="Europe/Moscow"
            :locale="'ru'"
            :max-date="pastDay"
            :popover="{ visibility: 'click' }"
            v-model="$v.reviewData.date.$model"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <b-input
                class="ph-36"
                :class="{ error: $v.reviewData.date.$error }"
                placeholder="ДД.ММ.ГГ"
                :value="inputValue"
                v-on="inputEvents"
                @blur="$v.reviewData.date.$touch"
              /> </template
          ></date-picker>
          <span class="warning" v-if="!$v.reviewData.date.required && $v.reviewData.date.$error"
            >Заполните это поле, чтобы продолжить.</span
          >
          <!-- <span class="warning">Отзыв нельзя оставить в будущем времени</span> -->
        </div>
        <div v-if="reviewData.customer_kind === 'shop'" class="form-part worth">
          <h3 class="form-part__title">Реклама окупилась</h3>
          <div class="row" name="paid_off">
            <b-button-group class="w-100">
              <b-button
                class="w-50"
                :variant="reviewData.paid_off ? 'primary' : 'outline-default'"
                @click="reviewData.paid_off = true"
              >
                Да
              </b-button>
              <b-button
                class="w-50"
                :variant="!reviewData.paid_off ? 'primary' : 'outline-default'"
                @click="reviewData.paid_off = false"
              >
                Нет
              </b-button>
            </b-button-group>
          </div>
          <span class="warning" v-if="false">Выберите вариант, чтобы продолжить</span>
        </div>
        <div class="form-part rate" id="rate">
          <h3 class="form-part__title">
            Оцените рекламу <i class="custom-star icon-star">&#xe800;</i>
            <span id="rating"> {{ reviewData.rate }}</span>
          </h3>
          <div class="row">
            <div class="stripe"></div>
            <div
              class="stripe stripe--dark"
              :style="{ width: (100 / 9) * (reviewData.rate - 1) + '%' }"
            ></div>
            <input
              style="margin-top: 2px"
              type="range"
              id="level"
              name="rate"
              min="1"
              max="10"
              v-model="reviewData.rate"
            />
          </div>
          <span class="warning warning--low_rate" v-if="reviewData.rate < 6">
            Если вы пишите негативный текст для отзыва, то каждое утверждение в нем должно быть
            подтверждено скриншотом. Без подтверждения модераторы не пропустят ваш отзыв или
            опубликуют его без текста
          </span>
        </div>
        <div class="form-part">
          <h3 class="form-part__title required-field-star">Формат рекламы</h3>
          <ad-type-select
            v-model="reviewData.ad_type"
            :options="telegramReviewsOptions.actions.POST.ad_type.choices"
          />
          <span
            class="warning"
            v-if="!$v.reviewData.ad_type.required && $v.reviewData.ad_type.$error"
            >Выберите вариант, чтобы продолжить</span
          >
        </div>
      </template>
      <template #step2>
        <div class="form-part">
          <h3 class="form-part__title" id="reach_title">
            Просмотры вашей рекламы
            <span class="fs-12 gray-text">(не обязательно) </span>
            <a
              target="_blank"
              class="link d-inline-block"
              @click="() => (showStatCoverageExModal = true)"
              >Пример</a
            >
          </h3>
          <input v-model="reviewData.largest_reach" type="number" class="input" />
        </div>
        <!--div class="form-part">
          <h3 class="form-part__title" id="reach_title">
            Сумма кликов по рекламе
            <span class="fs-12 gray-text">(не обязательно) </span>
            <a
              target="_blank"
              class="link d-inline-block"
              @click="() => (showStatCoverageExModal = true)"
              >Пример</a
            >
          </h3>
          <input v-model="reviewData.clicks_count" type="number" class="input" />
        </!--div-->
        <div class="form-part">
          <h3 class="form-part__title required-field-star">Приход подписчиков</h3>
          <input
            :class="{ error: $v.reviewData.arrival.$error }"
            v-model="$v.reviewData.arrival.$model"
            type="number"
            class="input"
          />
          <span
            class="warning"
            v-if="!$v.reviewData.arrival.required && $v.reviewData.arrival.$error"
            >Заполните это поле, чтобы продолжить</span
          >
        </div>
        <div class="form-part" v-if="reviewData.customer_kind === 'shop'">
          <h3 class="form-part__title required-field-star">Что вы рекламировали</h3>
          <input
            placeholder="Название товара/услуги"
            type="text"
            class="input"
            v-model="reviewData.item"
          />
          <span class="warning" v-if="!$v.reviewData.item.required && $v.reviewData.item.$error"
            >Заполните это поле, чтобы продолжить</span
          >
        </div>
        <div class="form-part price">
          <h3 class="form-part__title required-field-star">Цена за рекламу в ₽</h3>
          <div class="row form-part">
            <input
              class="input"
              type="number"
              min="0"
              step="1"
              @keypress="
                (event) => {
                  return event.charCode == 8 || event.charCode == 0
                    ? null
                    : event.charCode >= 48 && event.charCode <= 57;
                }
              "
              pattern="\d+"
              :class="{ error: $v.reviewData.price.$error }"
              v-model="$v.reviewData.price.$model"
            />
            <div class="only-blogger">
              <div class="boxes">
                <b-form-checkbox
                  v-model="reviewData.price_meta"
                  :unchecked-value="null"
                  value="together"
                  type="radio"
                >
                  Взаимопиар
                </b-form-checkbox>
                <b-form-checkbox
                  disabled
                  :checked="reviewData.price && reviewData.price_meta === 'together'"
                >
                  ВП + доплата
                </b-form-checkbox>
              </div>
            </div>
            <div class="only-commerce">
              <div class="boxes">
                <b-form-checkbox
                  v-model="reviewData.price_meta"
                  :unchecked-value="null"
                  value="barter"
                  type="radio"
                >
                  Бартер
                </b-form-checkbox>
                <b-form-checkbox
                  disabled
                  :checked="reviewData.price && reviewData.price_meta === 'barter'"
                >
                  Бартер + доплата
                </b-form-checkbox>
              </div>
            </div>
            <span
              class="warning"
              v-if="$v.reviewData.price.$anyError || $v.reviewData.price_meta.$anyError"
              >Заполните это поле или выберите чекбокс, чтобы продолжить</span
            >
          </div>
        </div>
        <div class="form-part upload">
          <h3 class="form-part__title required-field-star mb-2">
            Прикрепите скриншот с фактом выхода рекламы. Это может быть cкриншот поста в канале
            исполнителя:
          </h3>
          <screenshot-loader
            :detectCropped="true"
            v-model="reviewData.screenshots"
            uploadUrl="/api/reviews/upload-screenshot/"
          ></screenshot-loader>
          <span
            class="warning"
            v-if="!$v.reviewData.screenshots.required && $v.reviewData.screenshots.$error"
            >Загрузите один или несколько скриншотов, чтобы продолжить</span
          >
        </div>
        <section style="background-color: #fffce3; border-radius: 10px" class="p-3 mb-4 lh-18d6">
          ❗ Не обрезайте и не замазывайте скриншоты, иначе отзыв не будет принят
        </section>
      </template>
      <template #step3>
        <!--div
          v-if="reviewData.rate > 6"
          class="form-part d-flex justify-content-between align-items-center"
        >
          <span class="fs-16">Переключить на текст отзыва</span>
          <b-form-checkbox class="d-inline-block greenSwitch" v-model="textMode" switch size="lg" />
        </!--div-->
        <div v-if="textMode" class="form-part">
          <h3 class="form-part__title required-field-star">Текст отзыва</h3>
          <textarea
            type="text"
            class="input textarea"
            maxlength="300"
            rows="4"
            v-model="reviewData.text"
          ></textarea>
        </div>
        <div v-else>
          <p style="font-size: 15px" v-if="reviewData.rate >= 6">
            Поблагодарить блогера и его менеджера за:
          </p>
          <p style="font-size: 15px" v-if="reviewData.rate <= 5">
            Укажите, что не понравилось при сотрудничестве с блогером:
          </p>
          <p v-if="reviewData.rate <= 5" class="mt-3 gray-text">
            Можно указать только {{ tagsMax }} из {{ relevantRateTags.length }} пунктов
          </p>
          <b-button class="my-3" @click="reviewData.rate_tags = []" variant="link">
            Cнять отметки</b-button
          >
          <div class="d-flex flex-wrap justify-content-between">
            <div
              style="width: 48%; border: 1px solid #d5dfe4; border-radius: 5px; height: 42px"
              class="d-flex justify-content-between align-items-center my-2 px-2"
              v-for="tag in relevantRateTags"
              :key="tag.id"
            >
              <b-form-checkbox :value="tag.id" v-model="reviewData.rate_tags"></b-form-checkbox>
              <p style="line-height: 15.41px; width: 83%">{{ tag.text }}</p>
            </div>
            <div
              style="border: 1px solid #d5dfe4; border-radius: 5px; height: 42px"
              class="w-100 d-flex justify-content-between align-items-center my-3 px-2"
            >
              <b-form-checkbox></b-form-checkbox>
              <p class="text-left" style="line-height: 15.41px; width: 92%">
                {{ reviewData.rate >= 6 ? 'Р' : 'Не р' }}екомендую другим заказчикам
              </p>
            </div>
          </div>
        </div>
        <div class="form-part mt-4">
          <b-form-checkbox v-model="reviewData.agree">
            <span style="display: contents" class="option__title required-field-star"
              >Я соглашаюсь с
              <a target="_blank" style="display: contents" href="/policy" class="link"
                >Пользовательским соглашением</a
              ></span
            >
          </b-form-checkbox>
          <span class="warning" v-if="!$v.reviewData.agree.accepted && $v.reviewData.agree.$error"
            >Выберите этот чекбокс, чтобы продолжить</span
          >
        </div>
        <div class="form-part">
          <!--p class="gray-text" @click="showSusp = !showSusp">
            Если вы заподозрили блогера на наличие в чатах активностей и/или поддержки рекламы,
            нажмите сюда
            {{ showSusp ? '↑' : '↓' }}
          </p-->
          <div>
            <b-collapse v-model="showSusp" class="mt-2">
              <h3 class="form-part__title required-field-star">
                Выберите причину и загрузите скриншоты
              </h3>
              <div class="d-flex flex-wrap justify-content-between">
                <div
                  style="width: 100%; border: 1px solid #d5dfe4; border-radius: 5px; height: 60px"
                  class="d-flex justify-content-between align-items-center my-2 px-2"
                  v-for="reason in bsaReasons"
                  :key="reason.id"
                >
                  <p style="line-height: 15.41px; width: 94%">{{ reason.text }}</p>
                  <b-form-checkbox
                    :value="reason.id"
                    v-model="bloggerSuspiciousActivity.reasons"
                  ></b-form-checkbox>
                </div>
              </div>
              <b-collapse v-model="showBsaScreens">
                <screenshot-loader
                  v-model="bloggerSuspiciousActivity.screenshots"
                  uploadUrl="/api/reviews/upload-screenshot/"
                ></screenshot-loader> </b-collapse
            ></b-collapse>
          </div>
          <div></div></div
      ></template>
      <template #footer>
        <div class="bottom-part">
          <div class="inner-container">
            <button class="bottom-part__button button" @click="reset" v-if="currentStep === 1">
              Сброс
            </button>
            <b-button
              variant="outline-default"
              class="bottom-part__button button"
              @click="goLeft"
              v-else
              >Назад</b-button
            >
            <b-button
              variant="primary"
              class="bottom-part__button button"
              :disabled="loading || (review && review.left_resent_tries < 1)"
              @click="nextStep"
            >
              {{ loading ? 'Обработка...' : 'Далее' }}
            </b-button>
          </div>
        </div>
        <div
          class="bottom-part d-flex justify-content-center my-1 pt-3 pb-2"
          style="padding: 0px 57px; background-color: #f8f8f8"
        >
          <b-button
            style="
              line-break: anywhere !important;
              line-break: wrap !important;
              white-space: pre-wrap !important;
              max-width: 400px !important;
            "
            class="text-center d-block"
            target="_blank"
            href="https://tracker.easyprbot.com/bug-form"
            variant="link"
            >Нашли ошибку или не отправлется отзыв? Заполните эту форму</b-button
          >
        </div>
      </template>
    </MultiStepWindow>
    <template v-if="complete">
      <div class="page__popup flex w-sm-100" id="success-popup">
        <!--div class="review__success-pad">
          <div class="review--succes__content">
            <img src="../../assets/done_icon.png" alt="Успешно" class="form--3__image" />
            <h2 class="form__message">Отзыв отправлен на модерацию</h2>
            <b-button
              variant="outline-default"
              @click="reset"
              class="form--3__button button__success"
              >Оставить ещё один отзыв</b-button
            >
          </div>
        </!--div-->
        <review-success-ad @reset="reset" />
        <tracker-ad class="w-100 w-sm-auto" />
      </div>
    </template>
    <stat-coverage-examples-modal
      :show="showStatCoverageExModal"
      @hide="() => (showStatCoverageExModal = false)"
    />
    <StatExamplesModal :show="showStatExModal" @hide="() => (showStatExModal = false)" />
  </div>
</template>

<script>
import '../../styles/let_review.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { mapState, mapMutations } from 'vuex';
import { RequiresPhoneNumber } from '@main/func/pipeline';
import { required, minLength, between } from 'vuelidate/lib/validators';
import PhoneReviewWarning from '@main/components/reusable/elements/warnings/PhoneReviewWarning.vue';
import instagram from '@main/api/instagram';
import bloggers from '@main/api/bloggers';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import ReviewSuccessAd from '@main/components/ad/ReviewSuccessAd.vue';
import MultiStepWindow from '@main/components/reusable/windows/MultiStepWindow.vue';
import StatExamplesModal from '@main/components/reusable/modals/StatExamplesModal.vue';
import StatCoverageExamplesModal from '@main/components/reusable/modals/StatCoverageExamplesModal.vue';
import apiService from '../../api/index';
import FileInput from './FileInput.vue';
import InstanameInput from './InstanameInput.vue';
import AdTypeSelect from './ReviewAdTypeSelect.vue';
import TrackerAd from '../ad/TrackerAd.vue';
import reviewsApi from '../../api/reviews';
import ScreenshotLoader from '../reusable/forms/ScreenshotLoader.vue';

export default {
  components: {
    DatePicker,
    InstanameInput,
    Multiselect,
    AdTypeSelect,
    TrackerAd,
    StatExamplesModal,
    StatCoverageExamplesModal,
    MultiStepWindow,
    ReviewSuccessAd,
    ScreenshotLoader,
  },
  props: ['id', 'advertiser', 'customer', 'mutual', 'date', 'coverage'],
  computed: {
    ...mapState('reviewsModeration', ['refuseReasons']),
    ...mapState(['tags', 'reviewsOptions', 'telegramReviewsOptions']),
    relevantRateTags() {
      return this.rateTags.filter(
        (val) => val.rate_from <= this.reviewData.rate && val.rate_to >= this.reviewData.rate
      );
    },
    screenLink() {
      return `/reviews/screenshots-example/`;
    },
    pastDay() {
      return new Date(moment().subtract(1, 'days'));
    },
    showBsaScreens: {
      get() {
        return !!this.bloggerSuspiciousActivity.reasons.length;
      },
      set(val) {
        console.log(val);
      },
    },
  },
  data: () => ({
    tagsMax: 3,
    modelConfig: {
      type: 'string',
      mask: 'YYYY-MM-DD', // Uses 'iso' if missing
    },
    rateTags: [],
    bsaReasons: [],
    showSusp: false,
    textMode: false,
    complete: false,
    showStatCoverageExModal: false,
    showStatExModal: false,
    review: null,
    fileLoading: null,
    steps: 3,
    currentStep: 1,
    loading: false,
    reviewData: {
      customer: null,
      advertiser: null,
      price: null,
      price_meta: null,
      arrival: null,
      paid_off: true,
      date: new Date(moment().subtract(1, 'days')),
      customer_kind: 'blogger',
      rate: 10,
      customer_tags: [],
      item: null,
      agree: false,
      ad_type: null,
      text: '',
      screenshots: [],
      largest_reach: null,
      stories_count: null,
      rate_tags: [],
    },
    bloggerSuspiciousActivity: {
      instaname: '',
      reasons: [],
      screenshots: [],
    },
  }),
  methods: {
    ...mapMutations('notifications', ['showMessage']),
    goLeft() {
      if (this.currentStep > 1) {
        this.currentStep -= 1;
      }
    },
    checkFloat(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      // eslint-disable-next-line yoda
      if (48 <= charCode <= 57) {
        return true;
      }
      evt.preventDefault();
    },
    sendReview() {
      const action = this.review ? 'update' : 'create';
      if (!this.reviewData.price) {
        this.reviewData.price = null;
      }
      this.loading = true;
      reviewsApi.telegram[action](this.reviewData)
        .then((res) => {
          this.complete = true;
          this.showMessage({
            title: 'Отзыв успешно отправлен на модерацию',
            icon: 1,
            message: `<a class='btn btn-link' href='${window.location.origin}/#/reviews/new/'>Оставить ещё один отзыв</a>`,
          });
          try {
            if (
              this.bloggerSuspiciousActivity.reasons.length &&
              this.bloggerSuspiciousActivity.screenshots
            ) {
              this.bloggerSuspiciousActivity.instaname = this.reviewData.advertiser;
              this.bloggerSuspiciousActivity.review = res.id;
              reviewsApi.bloggerSuspiciousActivity
                .create(this.bloggerSuspiciousActivity)
                .then((resp) => {
                  window.console.log(resp);
                });
            }
          } catch (e) {
            console.log(e);
          } finally {
            bloggers
              .checkActionPermissions({ instaname: this.reviewData.advertiser }, 'create')
              .then((resp) => {
                this.$router.push({
                  name: 'fill_foreign_statistic',
                  params: { instaname: this.reviewData.advertiser },
                });
              })
              .catch((e) => {
                console.log(e);
                this.$router.push({
                  name: 'referal',
                });
              });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status >= 500) {
            this.showMessage({
              title:
                'Не удалось отправить отзыв, попробуйте позже или обратитесь в техническую поддержку!',
            });
          } else {
            this.showMessage({
              title:
                'Не удалось отправить отзыв, проверьте правильность данных или попробуйте переключить дату рекламы / обновить страницу и отправить еще раз!',
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async nextStep() {
      console.log(this.currentStep);
      this.$v[`step${this.currentStep}Group`].$touch();
      console.log(this.$v[`step${this.currentStep}Group`].$error);
      if (!this.$v[`step${this.currentStep}Group`].$anyError) {
        if (this.currentStep === 3) {
          this.sendReview();
        } else if (this.currentStep < this.steps) {
          this.currentStep += 1;
        }
      }
    },
    reset() {
      this.$router.push('/reviews/new/');
      this.review = null;
      this.complete = false;
      this.currentStep = 1;
      this.reviewData = {
        customer: null,
        advertiser: null,
        price: null,
        price_meta: null,
        arrival: null,
        paid_off: true,
        date: null,
        customer_kind: 'blogger',
        rate: 10,
        customer_tags: [],
        item: null,
        agree: false,
        ad_type: null,
        text: '',
        screenshots: [],
        coverage: null,
      };
      this.$v.$reset();
    },
    toggleprice_meta(val) {
      this.reviewData.price_meta = this.reviewData.price_meta === val ? null : val;
    },
  },
  async mounted() {
    const rateTags = await reviewsApi.rateTags.list();
    this.rateTags = rateTags.results;
    const bsaReasons = await reviewsApi.suspiciousActivityReasons.list();
    this.bsaReasons = bsaReasons.results;
    if (this.id) {
      try {
        this.review = await reviewsApi.telegram.get(this.id); // Загрузка данных из отзыва
        this.reviewData = {
          ...this.reviewData,
          ...this.review,
          date: new Date(moment(this.review.date)),
        };
      } catch (e) {
        this.showMessage({
          title: 'Ошибка!',
          message:
            'Не удается найти отзыв! Возможно, он был удален. Попробуйте перезагрузить страницу или отправьте отзыв заново!',
          icon: 2,
        });
      }
    }

    if (this.advertiser) this.$set(this.reviewData, 'advertiser', this.advertiser);
    if (this.customer) this.reviewData.customer = this.customer;
    if (this.date) this.reviewData.date = this.date;
    if (this.largest_reach) this.reviewData.largest_reach = this.largest_reach;
    if (this.mutual) {
      this.reviewData.customer_kind = 'blogger';
      this.reviewData.price_meta = 'together';
    }
  },
  validations: {
    reviewData: {
      customer: {
        required,
      },
      advertiser: {
        required,
      },
      arrival: {
        required,
      },
      date: {
        required,
      },
      customer_kind: {
        required,
      },
      item: {
        async required() {
          return this.reviewData.customer_kind === 'blogger' ? true : this.reviewData.item;
        },
      },
      agree: { accepted: (x) => x },
      customer_tags: {
        async required() {
          return this.reviewData.customer_kind === 'blogger'
            ? this.reviewData.customer_tags.length > 0
            : true;
        },
      },
      ad_type: { required },
      screenshots: { required },
      price: {
        async required() {
          return (
            ['barter', 'together'].includes(this.reviewData.price_meta) ||
            (this.reviewData.price && this.reviewData.price > 50)
          );
        },
      },
      price_meta: {
        async required() {
          return !!this.reviewData.price || this.reviewData.price_meta;
        },
      },
    },
    step1Group: [
      'reviewData.customer',
      'reviewData.advertiser',
      'reviewData.date',
      'reviewData.customer_kind',
      'reviewData.customer_tags',
      'reviewData.ad_type',
    ],
    step2Group: [
      'reviewData.screenshots',
      'reviewData.price',
      'reviewData.price_meta',
      'reviewData.arrival',
    ],
    step3Group: ['reviewData.agree'],
  },
  watch: {
    'reviewData.date': {
      handler(val) {
        console.log(val);
      },
    },
    'reviewData.price_meta': {
      handler(val) {
        console.log(val);
      },
    },
    'reviewData.rate_tags': {
      deep: true,
      handler(val) {
        if (this.reviewData.rate <= 5) {
          if (val.length > this.tagsMax) {
            val = val.splice(val.length - 2, 1);
            console.log(val);
          }
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.rate .row {
  margin-top: 15px !important;
  position: relative;
  width: 100%;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.ad-mode .button {
  width: 50%;
  line-height: 12px;
}

.rate .stripe {
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  top: 9px;
  z-index: 1;
  background-color: #d5dfe4;
}

.rate .stripe--dark {
  background-color: rgb(25, 34, 41);
}

.files-list >>> .file-wrapper:not(:last-child) {
  margin-bottom: 10px;
}

.textarea {
  height: 180px;
}

#success-popup {
  display: flex;
  flex-wrap: wrap;
}
#success-popup .popup {
  margin: auto;
  padding-bottom: 0;
  z-index: 0;
  max-width: 350px;
}
#success-popup .container {
  display: flex;
  padding: 30px 28px;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
#success-popup a {
  color: #000000;
  height: 36px;
  line-height: 16px;
  font-size: 14px;
  border: 1px solid #d5dfe4;
  border-radius: 5px;
  padding: 10px;
}
.review--succes__content {
  display: flex;
  padding: 0 15px;
  flex-direction: column;
  align-items: center;
}
.ad-wrapper {
  margin-top: 0px;
  margin-bottom: 5px;
}
#success-popup {
  align-items: center;
  display: flex !important;
  flex-wrap: wrap !important;
  flex-direction: row !important;
  justify-content: space-evenly !important;
  margin-top: 5%;
}
input::placeholder {
  color: gray !important;
}
.cloak {
  position: absolute !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s !important;
}
.fade-enter,
.fade-leave-to {
  opacity: 0 !important;
}
::v-deep .custom-switch.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-switch .cттustom-control-label::before {
  top: -0.1655rem;
  height: 1.95rem;
  left: -2.8125rem;
  width: 3.13rem;
  border-radius: 2.725rem;
  background-color: #c4c4c4;
  border-color: #c4c4c4;
}
::v-deep .custom-switch.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-switch .custom-control-label::after {
  top: calc(-0.2rem + 2px);
  left: calc(-2.8125rem + 2px);
  width: calc(1.92rem - 4px);
  height: calc(1.92rem - 4px);
  border-radius: 0.925rem;
  background-size: 50% 50%;
  background-color: white;
}
.greenSwitch {
  ::v-deep .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #3897f0;
    background-color: #35c75a;
    border-color: #35c75a;
  }
}
::v-deep
  .custom-switch.b-custom-control-lg
  .custom-control-input:checked
  ~ .custom-control-label::after,
.input-group-lg .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(1.18rem);
}
</style>
